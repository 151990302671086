
import { Component } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import PaymentForm from '@/components/venue/form/PaymentForm.vue';
import GeneralForm from '@/components/venue/form/GeneralForm.vue';
import SlotForm from '@/components/venue/form/SlotForm.vue';
import OrderForm from '@/components/venue/form/OrderForm.vue';
import PreorderForm from '@/components/venue/form/PreorderForm.vue';
import TerminalForm from '@/components/venue/form/TerminalForm.vue';
import CheckoutIntegrationForm from '@/components/venue/form/CheckoutIntegrationForm.vue';
import StackedForm from '@/mixins/StackedForm';
import { EditVenue as EditVenueMixin } from '@/mixins/EditVenue';
import { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import OpeningHourForm from '@/components/venue/form/OpeningHourForm.vue';
import DeliveryHourForm from '@/components/venue/form/DeliveryHourForm.vue';
import DeliverProvidersForm from '@/components/venue/form/DeliverProvidersForm.vue';
import ExternalOrderProvidersForm from '@/components/venue/form/ExternalOrderProvidersForm.vue';
import EditVenueLoader from '@/components/venue/EditVenueLoader.vue';
import DocumentForm from '@/components/venue/form/DocumentForm.vue';
import InvoiceForm from '@/components/venue/form/InvoiceForm.vue';
import { Permission } from '@/enums/Permission';
import { CustomerGroup } from '@/enums/CustomerGroup';
import OrderServiceApi from '@/api/http/OrderServiceApi';
import OrderOffsetForm from '@/components/venue/form/OrderOffsetForm.vue';
import PaymentApiService from '@/api/http/PaymentApiService';
import { VenueInvoiceType } from '@/enums/VenueInvoiceType';
import { VenueTransaction, VenueTransactions } from '@/interfaces/models/VenueTransactions';
import { AxiosError, AxiosResponse } from 'axios';
import { isAxiosError, toUrl } from '@/util/helper';
import { VenueTransactionType } from '@/enums/VenueTransactionType';
import { VenueTransactionStatus } from '@/enums/VenueTransactionStatus';
import ExternalDeliveryServiceForm from '@/components/venue/form/ExternalDeliveryServiceForm.vue';
import CateringHoursForm from '@/components/venue/form/CateringHoursForm.vue';
import SanifairForm from '@/components/venue/form/SanifairForm.vue';
import SalesforceForm from '@/components/venue/form/SalesforceForm.vue';
import DelfiForm from '@/components/venue/form/DelfiForm.vue';
import LoyaltyForm from '../../components/venue/form/LoyaltyForm.vue';
import venue from '@/stores/venue';
import { VTextField } from 'vuetify/lib';
import { ValidationObserver } from 'vee-validate';
import BoardingPassForm from '@/components/venue/form/BoardingPassForm.vue';
import DatevForm from '@/components/venue/form/DatevForm.vue';
import ExternalOrderProviderStatus from '@/components/venue/ExternalOrderProviderStatus.vue';

const venueInvoice = namespace('venueTransactions');
const app = namespace('app');

@Component({
  components: {
    BoardingPassForm,
    DatevForm,
    SanifairForm,
    CateringHoursForm,
    ExternalDeliveryServiceForm,
    OrderOffsetForm,
    EditVenueLoader,
    DeliveryHourForm,
    DeliverProvidersForm,
    ExternalOrderProvidersForm,
    OpeningHourForm,
    CheckoutIntegrationForm,
    TerminalForm,
    PreorderForm,
    OrderForm,
    SlotForm,
    DocumentForm,
    InvoiceForm,
    GeneralForm,
    PaymentForm,
    VWrapper,
    SalesforceForm,
    DelfiForm,
    LoyaltyForm,
    ExternalOrderProviderStatus,
  },
})
export default class EditVenue extends mixins(StackedForm, EditVenueMixin) {
  @venueInvoice.State('items') public venueTransactions!: VenueTransactions;
  @venueInvoice.Action('fetch') public getVenueTransactions!: any;

  @app.Action('updateVenue') public updateVenueFromAppStore!: any;

  public $refs!: {
    general: InstanceType<typeof GeneralForm> & { getData: () => any };
    payment: InstanceType<typeof PaymentForm> & { getData: () => any };
    order: InstanceType<typeof OrderForm> & { getData: () => any };
    preorder: InstanceType<typeof PreorderForm> & { getData: () => any };
    terminal: InstanceType<typeof TerminalForm> & { getData: () => any };
    ci: InstanceType<typeof CheckoutIntegrationForm> & {
      getData: () => any;
      initData: () => any;
      getInitiatedStatus: () => boolean;
      getIntegrationProperties: () => any;
    };
    slotting: InstanceType<typeof SlotForm> & { getData: () => any };
    documents: InstanceType<typeof DocumentForm> & { getData: () => any };
    openingHours: InstanceType<typeof OpeningHourForm> & { getData: () => any };
    deliveryHours: InstanceType<typeof DeliveryHourForm> & { getData: () => any };
    cateringHours: InstanceType<typeof CateringHoursForm> & { getData: () => any };
    deliveryProviders: InstanceType<typeof DeliverProvidersForm> & { getData: () => any };
    externalDeliveryService: InstanceType<typeof ExternalDeliveryServiceForm> & { getData: () => any };
    orderProviders: InstanceType<typeof ExternalOrderProvidersForm> & { getData: () => any };
    orderOffsets: InstanceType<typeof OrderOffsetForm> & { getData: () => any; getDailyUnsnoozeTime: () => any };
    sanifair: InstanceType<typeof SanifairForm> & { getData: () => any };
    saleseforce: InstanceType<typeof SalesforceForm> & { getData: () => any };
    delfi: InstanceType<typeof DelfiForm> & { getDelfi: () => any; initData: () => any; isCreated: () => boolean };
    loyalty: InstanceType<typeof LoyaltyForm> & { getData: () => any };
    observer: InstanceType<typeof ValidationObserver>;
    boardingPass: InstanceType<typeof BoardingPassForm> & { getData: () => any };
    datev: InstanceType<typeof DatevForm> & { getData: () => any };
  };

  public wideScreen = window.innerWidth >= 550;
  public drawer = this.wideScreen ? true : false;
  public ppAuthDialog: boolean = false;
  public ppAuthModel: { ppMerchantId: string; ppEmail: string } = {
    ppMerchantId: '',
    ppEmail: '',
  };

  public selectTab() {
    if (!this.wideScreen) {
      this.drawer = false;
    }
  }
  public handleResize() {
    this.wideScreen = window.innerWidth >= 550;
  }

  public async mounted(): Promise<void> {
    window.addEventListener('resize', this.handleResize);
  }

  public beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }

  get canMakeTestOrder(): boolean {
    return !!(
      this.$can(Permission.ORDER_TEST) &&
      this.editing &&
      this.venue &&
      this.venue.customerGroup === CustomerGroup.Subway &&
      this.venue.isPublished === true
    );
  }

  get shouldDisableRegister(): boolean {
    return !!(
      this.venue &&
      (this.venue.customerGroup === CustomerGroup.SubwayFr || this.venue.customerGroup === CustomerGroup.SubwayLu)
    );
  }

  get sepaMandate(): string {
    return (
      (this.venue &&
        this.venue.invoiceReceiver &&
        this.venue.invoiceReceiver.sepa &&
        this.venue.invoiceReceiver.sepa.sepaMandateRef) ||
      ''
    );
  }

  get sepaMandateUrl(): string {
    return this.sepaMandate ? `${process.env.VUE_APP_GOCARDLESS_URL}/mandates/${this.sepaMandate}` : '';
  }

  public loadVenueTransactions(): void {
    this.getVenueTransactions({ venue: this.$route.params.id });
  }

  public sendInvoice(selectedInvoiceType: VenueInvoiceType): void {
    this.$startLoading('venue.sendInvoice');
    const api: PaymentApiService = new PaymentApiService();
    api
      .sendBillomatInvoice({ venue: this.venue!._id, invoiceType: selectedInvoiceType })
      .then(async () => {
        await this.loadVenueTransactions();
        this.notifySuccess('venue.notification.sendInvoiceSuccess');
      })
      .catch((err: any) => {
        let msg: string = `${this.$t('venue.notification.sendInvoiceError')}`;

        if (!isAxiosError(err)) {
          msg += `${msg}: ${err.response.data?.message}`;
        }

        this.notifyError(msg);
      })
      .finally(() => {
        this.$stopLoading('venue.sendInvoice');
      });
  }

  public createCreditNote(data: { amount: string; note: string }): void {
    this.$startLoading('venue.sendInvoice');

    if (!data.amount || !data.note) {
      this.notifyError('venue.notification.createCreditNoteError');
      this.$stopLoading('venue.sendInvoice');
      return;
    }

    const creditNote: VenueTransaction = {
      type: VenueTransactionType.CREDIT_NOTE,
      status: VenueTransactionStatus.PENDING,
      amount: data.amount,
      note: data.note,
    };

    const api: PaymentApiService = new PaymentApiService();
    api
      .addTransaction({ venue: this.venue!._id, transaction: creditNote })
      .then(async () => {
        await this.loadVenueTransactions();
        this.notifySuccess('venue.notification.createCreditNoteSuccess');
      })
      .catch((err: any) => {
        let msg: string = `${this.$t('venue.notification.createCreditNoteError')}`;

        if (!isAxiosError(err)) {
          msg += `${msg}: ${err.response.data?.message}`;
        }

        this.notifyError(msg);
      })
      .finally(() => {
        this.$stopLoading('venue.sendInvoice');
      });
  }

  public sepaPay(transaction: VenueTransaction, description: string): void {
    const params: {
      id: string;
      description: string;
      invoiceNumber: string;
    } = { id: this.venue!._id, description, invoiceNumber: transaction.invoiceRef! };

    const api: PaymentApiService = new PaymentApiService();
    api
      .sepaPay(params)
      .then(async () => {
        await this.loadVenueTransactions();
        this.notifySuccess('venue.notification.sepa.paySuccess');
      })
      .catch((err: any) => {
        let msg: string = `${this.$t('venue.notification.sepa.payError')}`;

        if (!isAxiosError(err)) {
          msg += `${msg}: ${err.response.data?.message}`;
        }

        this.notifyError(msg);
      });
  }

  public downloadInvoicePdf(transaction: VenueTransaction): void {
    const api: PaymentApiService = new PaymentApiService();
    api
      .downloadInvoicePdf({ invoiceId: transaction.invoiceId! })
      .then((response: AxiosResponse) => {
        toUrl(response.data, `${transaction.invoiceRef}.pdf`);
      })
      .catch((err: AxiosError) => {
        this.notifyError('notification.downloadFailed');
      });
  }

  public downloadOrdersPdf(transaction: VenueTransaction): void {
    const api: PaymentApiService = new PaymentApiService();
    api
      .downloadOrdersPdf({ venue: this.venue!._id, transactionId: transaction._id! })
      .then((response: AxiosResponse) => {
        toUrl(response.data, `${transaction.provisionMonth}-Orders.pdf`);
      })
      .catch((err: AxiosError) => {
        this.notifyError('notification.downloadFailed');
      });
  }

  public testOrder(): void {
    const api: OrderServiceApi = new OrderServiceApi();
    api
      .testOrder({ venue: this.venue!._id })
      .then(() => {
        this.notifySuccess('venue.notification.testOrderSuccess');
      })
      .catch(() => {
        this.notifyError('venue.notification.testOrderError');
      });
  }

  public async connectPayPalAccount() {
    if (this.venue?.ppEmail && this.venue?.ppEmail !== '' && this.venue?.ppMerchantId) {
      this.authorizePayPal();
    } else {
      this.ppAuthModel.ppMerchantId = this.venue?.ppMerchantId ?? '';
      this.ppAuthModel.ppEmail = this.venue?.ppEmail ?? '';
      this.ppAuthDialog = true;
    }
  }

  public async connectByCustomCredentials() {
    if (!(await this.validatePayPalModel())) return;

    let data: { id: string; ppEmail: string; ppMerchantId: string } = { ...this.ppAuthModel, id: this.active._id };
    try {
      this.$startLoading('ppConnect');
      await this.update(data);
      await this.authorizePayPal();
    } catch (e) {
      console.log(e);
    } finally {
      this.ppAuthDialog = false;
      this.$stopLoading('ppConnect');
    }
  }

  public validatePayPalModel(): Promise<boolean> {
    return this.$refs.observer.validate();
  }

  public ppAuthClose() {
    this.ppAuthDialog = false;
  }

  public selecetCheckoutIntegrationTab() {
    this.$refs.ci.initData();
  }

  public selecetDelfiTab() {
    this.$refs.delfi.initData();
  }

  public async save(): Promise<void> {
    Promise.all(await this.validateWithKeys()).then(async (res: any) => {
      this.$startLoading('venue.save');
      const isValidWithKey: any = this.isValidWithKey(res);

      try {
        if (isValidWithKey.result) {
          const mergedData: any = this.getMergedData();
          const documents: { terms: string | Blob; imprint: string | Blob; privacy: string | Blob } = {
            ...mergedData.legal,
          };

          const data: any = this.sanitize(mergedData);

          // for not clear assets from form
          if (!data.assets && this.venue && this.venue.assets) {
            data.assets = this.venue.assets;
          }

          if (data.assets && data.assets.removed) {
            data.assets = null;
          }

          const isValidProps: { refKey: any; result: boolean } = await this.$refs.ci.isValidIntegrationProps();
          if (!isValidProps?.result) {
            this.notifyError('notification.form');
            return;
          }

          const dataConf: any = this.$refs.ci.getInitiatedStatus()
            ? this.sanitizeConf(this.$refs.ci.getIntegrationProperties())
            : null;

          if (this.editing) {
            data.id = this.active._id;
            await this.update(data);

            this.updateVenueFromAppStore(this.active);

            if (
              this.$can(Permission.VENUE_CHECKOUTINTEGRATION_UPDATE) &&
              dataConf && (
              data.checkoutIntegration === 'antara' ||
              data.checkoutIntegration === 'deliverect' ||
              data.checkoutIntegration === 'hellotess' ||
              data.checkoutIntegration === 'gastrofix' ||
              data.checkoutIntegration === 'simphony' ||
              data.checkoutIntegration === 'lightspeed_k_series' ||
              data.checkoutIntegration === 'vectron')
            ) {
              await this.setCheckoutIntegration({
                payload: dataConf,
                venue: this.active._id,
              });
            }

            if (this.$refs?.delfi?.isDelfi()) {
              await this.updateDelfi({ venue: this.active._id, payload: this.$refs?.delfi.getDelfi() });
            } else if (this.$refs?.delfi.getDelfi()) {
              await this.setDelfi({ payload: this.$refs?.delfi?.getDelfi() });
            }
          } else {
            await this.store(data);
          }

          if (!this.active) {
            this.$router.push({ name: 'venue.index', query: { ...this.$route.query } });
            this.$stopLoading('venue.save');
            return;
          }

          const formData: FormData = new FormData();
          if (data.nutritionTableFile) {
            formData.set('file', data.nutritionTableFile);
            await this.uploadNutritionTable({ id: this.active._id, file: formData });
          }
          if (data.selfCertificationFile) {
            formData.set('file', data.selfCertificationFile);
            await this.uploadSelfCertification({ id: this.active._id, file: formData });
          }
          if (documents.terms && documents.terms instanceof File) {
            formData.set('file', documents.terms);
            await this.uploadTermsAndConditions({ id: this.active._id, file: formData });
          }
          if (documents.imprint && documents.imprint instanceof File) {
            formData.set('file', documents.imprint);
            await this.uploadImprint({ id: this.active._id, file: formData });
          }
          if (documents.privacy && documents.privacy instanceof File) {
            formData.set('file', documents.privacy);
            await this.uploadPrivacy({ id: this.active._id, file: formData });
          }
          if (data.assets && data.assets instanceof File) {
            formData.set('asset', data.assets);
            await this.uploadImage({ id: this.active._id, file: formData });
          }
          if (
            data.externalDeliveryService &&
            data.externalDeliveryService.icon &&
            data.externalDeliveryService.icon instanceof File
          ) {
            formData.set('file', data.externalDeliveryService.icon);
            await this.uploadExternalDeliveryIcon({ id: this.active._id, file: formData });
          }
          this.$router.push({ name: 'venue.index', query: { ...this.$route.query } });
        } else {
          const errorTab: string = this.$t(`venue.tabs.${isValidWithKey.refKey}`).toString();
          const errorMsg: string = `${this.$t('notification.form')} (${errorTab})`;
          this.notifyError(errorMsg);
        }
      } catch (e) {
        console.log(e);
        this.notifyError('notification.500');
      } finally {
        this.$stopLoading('venue.save');
      }
    });
  }

  public getMergedData() {
    let data = {
      ...this.$refs.general.getData(),
      ...this.$refs.payment.getData(),
      ...this.$refs.order.getData(),
      ...this.$refs.preorder.getData(),
      ...this.$refs.terminal.getData(),
      ...this.$refs.ci.getData(),
      ...this.$refs.deliveryHours.getData(),
      ...this.$refs.cateringHours.getData(),
      ...this.$refs.deliveryProviders.getData(),
      ...this.$refs.orderProviders.getData(),
      ...this.$refs.openingHours.getData(),
      ...this.$refs.slotting.getData(),
      ...this.$refs.documents.getData(),
      ...this.$refs.orderOffsets.getData(),
      ...this.$refs.orderOffsets.getDailyUnsnoozeTime(),
      ...this.$refs.externalDeliveryService.getData(),
      ...this.$refs.sanifair.getData(),
      ...this.$refs.saleseforce.getData(),
      ...this.$refs.loyalty.getData(),
      ...this.$refs.boardingPass.getData(),
      ...this.$refs.datev.getData(),
    };

    return data;
  }
}
